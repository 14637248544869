import Flatpickr from 'stimulus-flatpickr'
import { Controller } from "@hotwired/stimulus"

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

  connect() {
   //global options
    this.config = {
      ...this.config,
      mode: 'range',
      dateFormat: 'd/m/Y'
    };

    //always call super.connect()
    super.connect();

    this.calendarContainerTarget.classList.add("has-predefined_ranges")
    let customRangePicker = document.createElement("div")

    let url = `/date_ranges`

    fetch(url)
      .then(response => response.text())
      .then(html => {
        let div = document.createElement("div")
        div.setAttribute("data-controller", "date-range-select")
        div.classList.add("flex", "h-full", "flex-col", "predefined_ranges", "w-32", "py-2")
        div.innerHTML = html
        this.calendarContainerTarget.insertBefore( div, this.monthNavTarget )
      }).catch((err) => {
        console.log(err);
      })

    customRangePicker.classList.add("flex", "h-full", "flex-col", "predefined_ranges", "w-32", "py-2")
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    if(selectedDates.length == 2){
      console.log(selectedDates);
      var url = window.location.href
      url = updateUrlParameter(url, "end_date", selectedDates[1].toLocaleDateString('fr-FR',{year: 'numeric', month: 'numeric', day: 'numeric'}))
      url = updateUrlParameter(url, "start_date", selectedDates[0].toLocaleDateString('fr-FR',{year: 'numeric', month: 'numeric', day: 'numeric'}))
      window.location.href = url;
    }

    // Add / Update a key-value pair in the URL query parameters
    function updateUrlParameter(uri, key, value) {
        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? ''  : uri.substr(i);
             uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }
        return uri + hash;  // finally append the hash as well
    }
  }
}
