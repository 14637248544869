import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import { get } from "@rails/request.js"//

export default class extends Controller {
  static targets = ["form", "results", "submit", "submit_parent", "loading"]
  connect() {
    let select_value = parseFloat(document.querySelector("select#platform_select").value);
    if(select_value == 3 || document.querySelector("select#platform_select").value == 4 || document.querySelector("select#platform_select").value == 35){
      //document.querySelector("#country_select").dataset.selectPlatformValue = select_value
      //document.querySelector("#country_select").dataset.controller = "select"
      document.querySelector("div#country_select_group").classList.remove("hidden")
    }
  }

  platform_select(event){
    if(event.target.value == 3 || event.target.value == 4 || event.target.value == 35){
      // document.querySelector("#country_select").dataset.selectPlatformValue = event.target.value
      // document.querySelector("#country_select").dataset.controller = "select_dropdown"
      document.querySelector("div#country_select_group").classList.remove("hidden")
      // get(`/countries?platform_id=${event.target.value}`, {
      //   responseKind: "turbo-stream"
      // })
    }
    else{
      document.querySelector("div#country_select_group").classList.add("hidden")
    }
  }

  contry_select(){

  }

  loading(e){
    this.loadingTarget.classList.remove('hidden');
    this.submitTarget.classList.add("hidden");
    this.submitTarget.disabled = true
    this.resultsTarget.classList.add("hidden");
    this.resultsTarget.querySelectorAll("li").forEach((el) => {
      el.remove();
    })

    let x = 0
    const check = setInterval(has_results, 500, this.resultsTarget, this.submitTarget, this.loadingTarget, x)

    function has_results(results, submit, loading){
      console.log(x);
      x ++;
      if ( results.querySelectorAll("li").length > 0 || x > 10 ){
        loading.classList.add('hidden');
        submit.classList.remove("hidden");
        submit.disabled = false
        results.classList.remove("hidden")
        clearInterval(check);
      }
      return false;
    }

    // Periodic checks until Search complete value is ok (1 or Platform.all.length – use a data-value attribute to pull it in the jS controller)
    // Disable spinner
    // Enable form
    // Show submit button

    // setInterval(function(){
    //   console.log(this.resultsTarget)
    //   if (resuts.querySelectorAll("li").length > 0){
    //     parent.dataset.loading = "false"
    //     loading.classList.add('hidden');
    //     submit.classList.remove("hidden");
    //     submit = false
    //     console.log("done")
    //   }
    // }, 500)

  }
}
