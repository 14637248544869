/* eslint no-console:0 */

// Import Sentry for error tracking

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

import * as Sentry from "@sentry/browser";

// Initialize Sentry
Sentry.init({
  dsn: "https://8766dc28a800fe250a5fe474ea1d3d9d@o4507259536605184.ingest.de.sentry.io/4507259540340816",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,  // Adjust this value as needed  
});


// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

import Chartkick from "chartkick"
import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'

const events = [
    "turbo:fetch-request-error",
    "turbo:frame-missing",
    "turbo:frame-load",
    "turbo:frame-render",
    "turbo:before-frame-render",
    "turbo:load",
    "turbo:render",
    "turbo:before-stream-render",
    "turbo:before-render",
    "turbo:before-cache",
    "turbo:submit-end",
    "turbo:before-fetch-response",
    "turbo:before-fetch-request",
    "turbo:submit-start",
    "turbo:visit",
    "turbo:before-visit",
    "turbo:click"
  ]
  
  // events.forEach(e => {
  //   addEventListener(e, () => {
  //     console.log(e);
  //   });
  // });

// Start Rails UJS
Rails.start()
