import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["providerSelect", "emailWrapper", "MSTeamsHelperWrapper", "MSTeamsWrapper", "submit", "slackSubmit", "zapierSubmit"]

  connect() {

  }

  update_form(event){
    console.log(event)
    switch(this.providerSelectTarget.value){
      case "email":        
        this.emailWrapperTarget.classList.remove("hidden")
        //this.webhookWrapperTarget.classList.contains("hidden") ? "" : this.webhookWrapperTarget.classList.add("hidden") ;
        this.MSTeamsWrapperTarget.classList.contains("hidden") ? "" : this.MSTeamsWrapperTarget.classList.add("hidden") ;
        this.slackSubmitTarget.classList.contains("hidden") ? "" : this.slackSubmitTarget.classList.add("hidden") ;
        this.submitTarget.classList.remove("hidden");
        this.zapierSubmitTarget.classList.contains("hidden") ? "" : this.zapierSubmitTarget.classList.add("hidden");
        break;
      case "webhook":        
        this.MSTeamsWrapperTarget.classList.remove("hidden")
        this.emailWrapperTarget.classList.contains("hidden") ? "" : this.emailWrapperTarget.classList.add("hidden") ;
        this.MSTeamsHelperWrapperTarget.classList.contains("hidden") ? "" : this.MSTeamsHelperWrapperTarget.classList.add("hidden") ;
        this.slackSubmitTarget.classList.contains("hidden") ? "" : this.slackSubmitTarget.classList.add("hidden") ;
        this.submitTarget.classList.remove("hidden");
        this.zapierSubmitTarget.classList.contains("hidden") ? "" : this.zapierSubmitTarget.classList.add("hidden");
        break;
      case "MSTeams":        
        this.MSTeamsWrapperTarget.classList.remove("hidden")
        this.MSTeamsHelperWrapperTarget.classList.remove("hidden")
        this.emailWrapperTarget.classList.contains("hidden") ? "" : this.emailWrapperTarget.classList.add("hidden") ;
        //this.webhookWrapperTarget.classList.contains("hidden") ? "" : this.webhookWrapperTarget.classList.add("hidden") ;
        this.slackSubmitTarget.classList.contains("hidden") ? "" : this.slackSubmitTarget.classList.add("hidden") ;
        this.submitTarget.classList.remove("hidden");
        this.zapierSubmitTarget.classList.contains("hidden") ? "" : this.zapierSubmitTarget.classList.add("hidden");
        
        break;
      case "slack":        
        this.emailWrapperTarget.classList.contains("hidden") ? "" : this.emailWrapperTarget.classList.add("hidden") ;
        //this.webhookWrapperTarget.classList.contains("hidden") ? "" : this.webhookWrapperTarget.classList.add("hidden") ;
        this.MSTeamsWrapperTarget.classList.contains("hidden") ? "" : this.MSTeamsWrapperTarget.classList.add("hidden") ;
        this.slackSubmitTarget.classList.remove("hidden")
        this.submitTarget.classList.contains("hidden") ? "" : this.submitTarget.classList.add("hidden") ;
        this.zapierSubmitTarget.classList.contains("hidden") ? "" : this.zapierSubmitTarget.classList.add("hidden");
        break;
      case "zapier":
        this.emailWrapperTarget.classList.contains("hidden") ? "" : this.emailWrapperTarget.classList.add("hidden") ;
        //this.webhookWrapperTarget.classList.contains("hidden") ? "" : this.webhookWrapperTarget.classList.add("hidden") ;
        this.MSTeamsWrapperTarget.classList.contains("hidden") ? "" : this.MSTeamsWrapperTarget.classList.add("hidden") ;
        this.slackSubmitTarget.classList.contains("hidden") ? "" : this.slackSubmitTarget.classList.add("hidden") ;
        this.submitTarget.classList.contains("hidden") ? "" : this.submitTarget.classList.add("hidden") ;
        this.zapierSubmitTarget.classList.remove("hidden");
        break;
    }
  }



  disconnect() {
  }
}
