import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  static targets = ["input", "hint", "menu", "button"]

  connect() {
    super.connect();    
  }

  toggle(event) {
    super.toggle(event);
  }

  select(event) {    
    const selectedOption = event.currentTarget;
    const value = selectedOption.getAttribute("data-value");

    // Update the hidden input with the selected value
    this.inputTarget.value = value;

    // Find the widest option and set the button's width
    let widestWidth = 0;
    this.menuTarget.querySelectorAll("li").forEach(option => {
        const optionWidth = option.offsetWidth;
        if (optionWidth > widestWidth) {
            widestWidth = optionWidth;
        }
    });

    // Set the button's width to match the widest option
    this.buttonTarget.style.width = `${widestWidth}px`;

    // Clear the hint target (the button's inner content)
    this.hintTarget.innerHTML = '';

    // Create a container to wrap the icon and text, and apply flexbox styling
    const wrapperDiv = document.createElement("div");
    wrapperDiv.classList.add("inline-flex", "items-center", "space-x-2");  // Flexbox classes for proper alignment

    // Get the label and image from the selected option
    const label = selectedOption.querySelector("span").textContent.trim();
    const icon = selectedOption.querySelector("img");

    // If an icon exists, clone and append it to the wrapper
    if (icon) {
        const clonedIcon = icon.cloneNode(true);
        clonedIcon.classList.add("h-4");  // Keep the icon size consistent
        wrapperDiv.appendChild(clonedIcon);
    }

    // Create a span for the label text and append it to the wrapper
    const labelSpan = document.createElement("span");
    labelSpan.textContent = label;
    wrapperDiv.appendChild(labelSpan);

    // Append the wrapper to the hint target (button content)
    this.hintTarget.appendChild(wrapperDiv);

    // Close the dropdown
    this.menuTarget.classList.add("hidden");    

    const submitOnSelect = this.element.dataset.submitOnSelect === 'true'; 

    if (submitOnSelect) {
        // Find the closest form and submit it
        this.inputTarget.closest('form').submit();
    }
  }


  hide(event) {
    super.hide(event);
  }
}
